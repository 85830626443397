<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="unprocessedPackages"
      :loading="loading"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length="getUnprocessedPackagesCount || options.itemsPerPage * totalPages"
      :footer-props="footerProps"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-container fluid>
          <v-row class="mt-2 mb-4">
            <v-col cols="12" sm="6" md="3" lg="2">
              <v-btn color="primary" @click="paginateTo">
                <v-icon>mdi-autorenew</v-icon>
                Оновити дані
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-2 mb-4">
            <v-col cols="12" sm="6" md="3" lg="2">
              <v-datetime-picker
                v-model="fromDate"
                @input="paginateTo"
                :textFieldProps="textFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                timeFormat="HH:mm:ss"
                dateFormat="dd-MM-yyyy"
                clear-text="очистити"
                ok-text="так"
                label="Дата та час від"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="2" offset-md="1">
              <v-datetime-picker
                v-model="toDate"
                @input="paginateTo"
                :textFieldProps="textFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                dateFormat="dd-MM-yyyy"
                timeFormat="HH:mm:ss"
                clear-text="очистити"
                ok-text="так"
                label="Дата та час до"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.terminal_packet_date_time="{ item }">
        <div>{{ item.terminal_packet_date_time | getShortTime }}</div>
        <span class="caption">{{ item.terminal_packet_date_time | getShortDate }}</span>
      </template>
      <template v-slot:item.error="{ item }">
        <code>{{ item.error }}</code>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" small @click.stop="openDialogPacket(item.packet, 'info')" v-bind="attrs" v-on="on">
              mdi-package-variant
            </v-icon>
          </template>
          <span>Інфо пакету</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="resetData"> Оновити </v-btn>
      </template>
    </v-data-table>

    <LogsPackageModal
      @closePackageDialog="dialogPacket = false"
      :dialogPacket="dialogPacket"
      :dialogPacketType="dialogPacketType"
      :selectedPacket="selectedPacket"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ConvertDate from '@/mixins/convertDate'
import OpenLogsPackageDialog from '@/mixins/openLogsPackageDialog'
import resetTableData from '@/mixins/resetTableData'
import moment from 'moment'

const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export default {
  name: 'UnprocessedPackages',

  components: {
    LogsPackageModal: () => import('@/components/dialogs/LogsPackageModal'),
  },

  mixins: [ConvertDate, OpenLogsPackageDialog, resetTableData],

  data: () => ({
    MENU_MIN_WIDTH: 450,
    MENU_MAX_WIDTH: 450,
    packageTypesMenu: false,
    loading: false,
    sortDesc: true,
    sortBy: '',
    options: {},
    page: 1,
    textFieldProps: {
      hideDetails: 'auto',
      dense: true,
    },
    datePickerProps: {
      'first-day-of-week': 1,
    },
    timePickerProps: {
      format: '24hr',
      useSeconds: true,
    },
    selectedPacket: null,
    fromDate: null,
    toDate: null,
    totalPages: 2,
    headers: [
      {
        text: 'Дата і час',
        value: 'terminal_packet_date_time',
        sortable: true,
        width: 120,
      },
      { text: 'Помилки сервера', value: 'error', sortable: false },
      { text: '', value: 'actions', sortable: false, width: '75px', align: 'left' },
    ],
  }),

  computed: {
    ...mapState('logs', ['unprocessedPackages']),
    ...mapState('dictionaries', ['packageTypesDictionary']),

    ...mapGetters('logs', ['getUnprocessedPackagesCount']),

    currentCompanyId() {
      return this.$route.params.id
    },
    sortDirection() {
      if (this.options.sortBy.length === 1 && this.options.sortDesc.length === 1) {
        return this.options.sortDesc[0] ? 'desc' : 'asc'
      } else return ''
    },
    convertedFromDate() {
      if (!this.fromDate) return ''
      return moment(this.fromDate).format(DEFAULT_DATE_TIME_FORMAT)
    },
    convertedToDate() {
      if (!this.toDate) return ''
      return moment(this.toDate).format(DEFAULT_DATE_TIME_FORMAT)
    },
    footerProps() {
      return {
        'items-per-page-options': [5, 10, 15, 20],
        'show-current-page': true,
        'show-first-last-page': !!this.getUnprocessedPackagesCount,
        ...(!this.getUnprocessedPackagesCount && { 'page-text': '{0}-{1}' }),
      }
    },
  },

  watch: {
    options: {
      handler(val) {
        if (!this.getUnprocessedPackagesCount && val.page === this.totalPages) {
          this.totalPages++
        }
        this.paginateTo()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('logs', ['loadAllUnprocessedPackages', 'displayWarningAlert']),

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const params = {
          companyId: this.currentCompanyId,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          /* *** sort by 'terminal_packet_date_time' =>> 'time' | see CM-511 *** */
          // sort: this.options.sortBy[0],
          direction: this.sortDirection,
          fromDate: this.convertedFromDate,
          toDate: this.convertedToDate,
        }
        await this.loadAllUnprocessedPackages(params)
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
  },
}
</script>
